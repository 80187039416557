import React                from 'react'
import SocialMediaLinks     from './SocialMediaLinks'


const ConnectWithUs = () => {

    return (
    <>
    <section id="connectWithUs" className="connectWithUs animate__animated animate__slideInUp">
        <h2>Connect</h2>
        <SocialMediaLinks />
    </section>
    </>
    )
}

export default ConnectWithUs
