import React                from 'react'
import ThumbnailCarousel    from './ThumbnailCarousel'


const Portfolio = () => {

    const thumbnails = [
        {
            link: "https://www.stargoddess.co",
            rel: "external",
            image: "img/stargoddess-astrology-tarot-readings.jpg",
            alt: "Astrology and Tarot Readings by StarGoddess",
            text: "Astrology and Tarot Readings by StarGoddess"
        },
        {
            link: "https://www.ecomaccountingsolutions.com",
            rel: "noopener noreferrer nofollow external",
            image: "img/squeezed-ecomaccountingsolutions.jpg",
            alt: "eComnerce Accounting Solutions",
            text: "eCommerce Accounting Solutions"
        },
        {
            link: "https://www.edenandross.com",
            rel: "noopener noreferrer nofollow external",
            image: "img/squeezed-edenandross.jpg",
            alt: "Eden and Ross Bathrooms",
            text: "Eden and Ross Bathrooms"
        },
        {
            link: "https://www.osullivanplaybeds.co.uk",
            rel: "noopener noreferrer nofollow external",
            image: "img/squeezed-osullivanplaybeds.jpg",
            alt: "O'Sullivan Playbeds",
            text: "O'Sullivan Playbeds"
        },
        {
            link: "https://bewitched.cleaning",
            rel: "noopener noreferrer nofollow external",
            image: "img/squeezed-bewitched.jpg",
            alt: "Bewitched Cleaning",
            text: "Bewitched Cleaning"
        }
    ];

    return (
    <>
    <section id="Portfolio" className="portfolio animate__animated animate__slideInUp">
        <h2>Recent Portfolio</h2>
        <ThumbnailCarousel thumbnails={thumbnails} />
    </section>
    </>
    )
}

export default Portfolio
