import React from 'react'

const SocialMediaLinks = () => {

    const facebookLink  = process.env.REACT_APP_FB_LINK;
    const twitterLink   = process.env.REACT_APP_TWITTER_LINK;
    const youtubeLink   = process.env.REACT_APP_YOUTUBE_LINK;
    const instagramLink = process.env.REACT_APP_INSTAGRAM_LINK;

    const w = 36;
    const h = 36;

    return (
      <section id="SocialMediaLinks">
      <div className="SocialMediaLinks">

        {/* Twitter */}
        <a
          className="me-4"
          href={twitterLink}
          alt="Check me out on X"
          rel="noopener noreferrer nofollow external"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 462.799" fill="currentColor">
            <path fill-rule="nonzero" d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"/>
          </svg>
        </a>

        {/* Facebook */}
        <a
          className="me-4"
          href={facebookLink}
          alt="We're on FaceBook too"
          rel="noopener noreferrer nofollow external"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 24 24" fill="currentColor">
            <path d="M22.675 0h-21.35C.598 0 0 .593 0 1.326v21.348c0 .733.598 1.326 1.326 1.326h11.495v-9.294H9.69v-3.622h3.131V7.918c0-3.1 1.893-4.788 4.658-4.788 1.325 0 2.464.099 2.796.143v3.24h-1.918c-1.504 0-1.794.716-1.794 1.765v2.31h3.587l-.467 3.622h-3.12v9.294h6.116c.728 0 1.326-.593 1.326-1.326V1.326C24 .593 23.402 0 22.675 0z" />
          </svg>
        </a>

        {/* YouTube */}
        <a
          className="me-4"
          href={youtubeLink}
          alt="The Dramallama YouTube Channel"
          rel="noopener noreferrer nofollow external"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 24 24" fill="currentColor">
            <path d="M19.615 3.184C21.202 3.648 22 5.112 22 12c0 6.888-.798 8.352-2.385 8.816C17.798 21.648 12 22 12 22s-5.798-.352-7.385-.816C2.798 20.352 2 18.888 2 12c0-6.888.798-8.352 2.385-8.816C6.202 2.352 12 2 12 2s5.798.352 7.385.816zM9.54 8.26v7.48l6.261-3.74z" />
          </svg>
        </a>

        {/* Instagram */}
        <a
          className="me-4"
          href={instagramLink}
          alt="Dramallama is on Instagram"
          rel="noopener noreferrer nofollow external"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 24 24" fill="currentColor">
            <path d="M7.001 2C3.687 2 2 3.686 2 7v10c0 3.314 1.686 5 5 5h10c3.314 0 5-1.686 5-5V7c0-3.314-1.686-5-5-5H7.001zM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm6-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
        </a>

        {/*email*/}
        <a
          className="me-4"
          href="mailto:enquiries@dramallama.ltd"
          alt="Send us an email"
          rel="noopener noreferrer nofollow external"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 24 24" fill="currentColor">
            <path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 2v.01L12 12 4 6.01V6h16zm-16 2.46 6.99 4.65c.31.21.71.21 1.02 0L20 8.46V18H4V8.46z"/>
          </svg>
        </a>
      </div>
      </section>
  );
}

export default SocialMediaLinks
