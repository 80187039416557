import React from 'react'

const Header = () => {

    // pro tip - don't lazy load images above the fold, they delay the LCP

    return (
    <>
    <header>
        <div id="logo" className=" unique animate__animated animate__slideInRight">
            <img src="img/dramallama_logo_small.png" alt="dramallama.limited" width="300px" />
        </div>

        <h1>dramallama limited</h1>
    </header>
    </>
    )
}

export default Header

