import React from 'react'

const Services = () => {
    return (
    <>
    <section id="Services" className="animate__animated animate__slideInDown">
        <h2>Services</h2>
        <ul>
            <li>Small Business Website Design</li>
            <li>Content Creation</li>
            <li>Online Presence Management</li>
            <li>Using WordPress, React, CSS & THREE</li>
        </ul>
    </section>
    </>
    )
}

export default Services
