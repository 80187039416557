import React, { useState, useEffect, useRef } from 'react';
import styles from './ThumbnailCarousel.module.css';

const ThumbnailCarousel = ({ thumbnails }) => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start at 1 for the first real thumbnail
  const [isPaused, setIsPaused] = useState(false);
  const containerRef = useRef(null);
  const itemWidthRef = useRef(0); // To store the calculated width of each thumbnail

  // Calculate item width dynamically
  const calculateItemWidth = () => {
    if (containerRef.current && containerRef.current.firstChild) {
      itemWidthRef.current = containerRef.current.firstChild.offsetWidth;
    }
  };

  // Scroll to the current index
  const scrollToIndex = (index) => {
    if (containerRef.current) {
      calculateItemWidth();
      const itemWidth = itemWidthRef.current;
      containerRef.current.style.transition = 'transform 0.5s ease-in-out';
      containerRef.current.style.transform = `translateX(-${index * itemWidth}px)`;
    }
  };

  const getRealIndex = () => {
    if (currentIndex === 0) return thumbnails.length - 1; // Last real thumbnail
    if (currentIndex === thumbnails.length + 1) return 0; // First real thumbnail
    return currentIndex - 1; // Adjust for 1-based index and clone at the start
  };

  // Auto-slide logic
  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, [isPaused]);

  // Trigger scrolling whenever currentIndex changes
  useEffect(() => {
    scrollToIndex(currentIndex);
  }, [currentIndex]);

  // Handle infinite scrolling
  const handleTransitionEnd = () => {
    const itemWidth = itemWidthRef.current;

    if (currentIndex >= thumbnails.length + 1) {
      setCurrentIndex(1); // Reset to the first real thumbnail
      containerRef.current.style.transition = 'none';
      containerRef.current.style.transform = `translateX(-${itemWidth}px)`;
    } else if (currentIndex === 0) {
      setCurrentIndex(thumbnails.length); // Reset to the last real thumbnail
      containerRef.current.style.transition = 'none';
      containerRef.current.style.transform = `translateX(-${thumbnails.length * itemWidth}px)`;
    }
  };

  // Pause/Resume on hover
  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  return (
    <div
      className={styles.thumbnailCarousel}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={styles.carouselContainer}
        ref={containerRef}
        onTransitionEnd={handleTransitionEnd}
      >
        {/* Add clones and original thumbnails */}
        {[thumbnails[thumbnails.length - 1], ...thumbnails, thumbnails[0]].map(
          (thumb, index) => (
            <div key={index} className={styles.thumbnail}>
              <a href={thumb.link} rel={thumb.rel}>
                <img src={thumb.image} alt={thumb.alt} />
              </a>
            </div>
          )
        )}
      </div>

      <div className={styles.indicators}>
        {thumbnails.map((_, index) => (
          <span
            key={index}
            className={getRealIndex() === index ? styles.active : ''}
            onClick={() => setCurrentIndex(index + 1)} // Set index to the corresponding real thumbnail
          />
        ))}
      </div>

    </div>
  );
};

export default ThumbnailCarousel;
