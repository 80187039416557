import React, { useEffect, useState } from 'react'

import Cube                 from '../components/Cube'
import Header               from '../components/Header'
import ConnectWithUs        from '../components/ConnectWithUs'
import Services             from '../components/Services'
import Portfolio            from '../components/Portfolio'
import TrustPilot           from '../components/TrustPilot'

import './LandingScreen.css'


const LandingScreen = () => {

    const [isMobile, setIsMobile] =
        useState(window.matchMedia('(max-width: 767px)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');

        const handleMediaChange = (e) => {
            setIsMobile(e.matches);
        };

        mediaQuery.addListener(handleMediaChange);

        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);


  return (
    <>
    <div className="page" id="LandingScreen">

        <div className="underlay">
        {
            ! isMobile && <Cube />
        }
        </div>

        <div className="content">

            <div className="text-center">

                <Header />

                <main>
                    <Services />
                    <ConnectWithUs />
                    <Portfolio />
                </main>

                <footer>
                    <TrustPilot />
                    <p className="small">&copy; 2023-4 dramallama.limited, all rights reserved.</p>
                </footer>
            </div>
        </div>
    </div>
    </>
  );
};

export default LandingScreen;
